<template>
  <v-row v-if="project && project.id">
    <v-card class="col-12 mt-6">
      <v-card-title>Project</v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          Name
        </v-col>
        <v-col
          cols="12"
          md="9"
          v-text="project.name"
        ></v-col>

        <v-col
          cols="12"
          md="3"
        >
          Description
        </v-col>
        <v-col
          cols="12"
          md="9"
          v-text="project.description"
        ></v-col>

        <v-col
          cols="12"
          md="3"
        >
          Enabled
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <v-switch v-model="project.enabled"></v-switch>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-6 w-full">
      <v-tabs centered>
        <v-tab>
          API Keys
        </v-tab>
<!--        <v-tab>-->
<!--          Teams-->
<!--        </v-tab>-->
<!--        <v-tab>-->
<!--          <v-icon left>-->
<!--            mdi-access-point-->
<!--          </v-icon>-->
<!--          Option 3-->
<!--        </v-tab>-->

        <v-tab-item>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  Description
                </th>
                <th class="text-uppercase">
                  Api Key
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="apiKey in project.apiKeys"
                :key="apiKey.id"
              >
                <td>{{ apiKey.description }}</td>
                <td>
                  {{ apiKey.api_key }}
                  <v-btn icon small class="ml-2" @click="copyToClipboard(apiKey.api_key)">
                    <v-icon small>
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <v-dialog
                  v-model="apiDialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <td colspan="2"
                        class="text-center"
                        v-bind="attrs"
                        v-on="on">
                      <v-icon class="no-msg-icon">
                        {{ icons.mdiPlus }}
                      </v-icon>
                      Add Api Key
                    </td>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Add New Api Key
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label for="apiDescription">Description</label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="9"
                        >
                          <!--            :prepend-inner-icon="icons.mdiAccountOutline"-->
                          <v-text-field
                            id="apiDescription"
                            v-model="apiDescription"
                            outlined
                            dense
                            placeholder="Description"
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="addApiKey"
                      >
                        Add
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="apiDialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
<!--        <v-tab-item>-->
<!--          <v-card class="pt-2" flat>-->
<!--            <teams></teams>-->
<!--          </v-card>-->
<!--        </v-tab-item>-->
<!--        <v-tab-item>-->
<!--          <v-card flat>-->
<!--            <v-card-text>-->
<!--              <p>-->
<!--                Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.-->
<!--              </p>-->

<!--              <p class="mb-0">-->
<!--                Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.-->
<!--              </p>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-tab-item>-->
      </v-tabs>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from 'axios'
import {
  mdiDelete,
  mdiPlus,
  mdiContentCopy,
} from '@mdi/js'
import Teams from '../../components/Teams'
import {copyToClipboard} from '@/helpers/helpers';

export default {
  components: {
    Teams
  },
  data() {
    return {
      loading: false,
      project: {},
      error: null,

      snackbar: false,
      snackbarText: '',
      apiDialog: false,

      apiDescription: '',

      icons: {
        mdiPlus,
        mdiContentCopy,
        mdiDelete
      }
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },

      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true },
    )
  },
  methods: {
    fetchData() {
      this.error = this.project = null
      this.loading = true
      this.getProject((err, project) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.project = project
          console.log(project)
        }
      })
    },
    getProject(callback) {
      axios
        .get(`project/view/${this.$route.params.id}`)
        .then(response => callback(null, response.data.data))
        .catch(error => callback(error))
    },

    addApiKey() {
      axios.post(`project/add-api-key/${this.$route.params.id}`, { description: this.apiDescription })
        .then(response => {
          this.apiDescription = ''
          this.error = null
          this.snackbarText = 'Api key successfully added.'
          this.apiDialog = false
          this.snackbar = true
          this.fetchData()
        })
        .catch(error => this.error = error)
    },

    copyToClipboard(text) {
      copyToClipboard(text)
      this.$store.dispatch('addAlertMsg', {text: 'Copied!'})
    }
  },
}
</script>
