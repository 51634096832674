<template>
  <div>
    <div v-if="currentProject && currentProject.id && (currentProject.user_id === $auth.user().id)">
      <v-dialog
        v-model="addMemberDialog"
        max-width="380px"
      >
        <v-card>
          <v-card-title>Add Member</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="newMemberEmail"
              class="mt-4"
              outlined
              label="Email"
            ></v-text-field>

            <v-select
              v-model="newMemberGroup"
              outlined
              dense
              label="Group"
              :items="membersGroups"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              @click="addMemberDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="inviteMember"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div v-if="currentProject">
        <v-row class="w-full">
          <v-card class="mt-0 pa-0 w-full">
            <v-card-title class="text-center w-full">
              Project Members
            </v-card-title>

            <!--          <v-divider></v-divider>-->

<!--            <div class="pa-2 pl-4 text-left">-->
<!--              Owner: {{ owner.name }}-->
<!--            </div>-->
          </v-card>

          <v-card
            v-if="currentProjectInvites && currentProjectInvites.length"
            class="mt-6 pa-0 w-full"
          >
            <v-card-title>Invites</v-card-title>
            <v-simple-table class="w-full">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(member, idx) in currentProjectInvites"
                    :key="member.user_email"
                    class="list-group-item w-full pa-0 pt-4 ma-0 row"
                  >
                    <td class="col-4">
                      <span>{{ member.user_email }}</span>
                    </td>
                    <td class="col-4">
                      <span>{{ getMemberGroupLabel(member.role) }}</span>
                    </td>
                    <td class="col-3">
                      <v-btn
                        link
                        depressed
                        small
                        outlined
                        @click="resentInvite(member.id)"
                      >
                        Re-send invitation
                      </v-btn>
                    </td>
                    <td class="col-1">
                      <v-icon
                        class="no-msg-icon delete-task-btn"
                        @click="deleteInvite(member.id)"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="mt-6 pa-0 w-full">
            <v-card-title>Members</v-card-title>
            <v-simple-table class="w-full">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(member, idx) in currentProjectUsers"
                    :key="member.email"
                    :v-key="idx"
                    class="list-group-item w-full pa-0 pl-2 pt-2 ma-0 row"
                  >
                    <td class="col-1">
                      <v-avatar
                        color="primary"
                        size="36"
                      >
                        {{ member.short_name }}
                      </v-avatar>
                    </td>
                    <td class="col-4 pt-2">
                      <span>{{ member.user_name }}</span>
                    </td>
                    <td class="col-4 pt-2">
                      <span>{{ member.user_email }}</span>
                    </td>
                    <td class="col-2 pt-2">
                      <span>{{ member.role }}</span>
                    </td>
                    <td class="col-1 pt-2">
                      <v-icon
                        class="no-msg-icon delete-task-btn"
                        @click="deleteUser(member.id)"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td colspan="5">
                      <button
                        class="btn btn-secondary button"
                        @click="addMemberAction()"
                      >
                        <v-icon class="no-msg-icon">
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="mt-12 pa-4 w-full text-center">
            Teams will be here soon.
          </v-card>
        </v-row>
      </div>

      <v-row
        v-if="!currentProject"
        class="w-full"
      >
        <v-card class="mt-6 pa-4 w-full text-center">
          Choose Project
        </v-card>
      </v-row>
    </div>

    <v-card v-if="currentProject && currentProject.id  && (currentProject.user_id !== $auth.user().id)">
      <v-card-title class="text-center">
        ⚠️ You don't have access to this section
      </v-card-title>
    </v-card>

    <choose-project v-if="!currentProject || !currentProject.id"></choose-project>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiDelete,
  mdiPlus,
} from '@mdi/js'
import ChooseProject from './ChooseProject'

export default {
  components: {
    ChooseProject,
  },
  setup() {
    return {

    }
  },

  data() {
    return {
      newMemberEmail: '',
      newMemberGroup: '',
      addMemberDialog: false,

      // owner: {
      //   name: 'Mik Isa',
      // },
      icons: {
        mdiDelete,
        mdiPlus,
      },
    }
  },
  watch: {
    async currentProject() {
      await this.$store.dispatch('setSectionOverlay', true)
      await this.$store.commit('SET_PROJECT_USERS_LIST', [])
      await this.$store.commit('SET_PROJECT_INVITES_LIST', [])
      await this.$store.dispatch('getUsersList')
      await this.$store.dispatch('getInvitesList')
      await this.$store.dispatch('setSectionOverlay', false)
    },
  },
  created() {

  },
  computed: {
    ...mapState([
      'currentProjectUsers',
      'currentProjectInvites',
      'membersGroups',
      'currentProject',
    ]),
  },
  async mounted() {
    await this.$store.dispatch('setSectionOverlay', true)
    await this.$store.dispatch('getUsersList')
    await this.$store.dispatch('getInvitesList')
    await this.$store.dispatch('setSectionOverlay', false)
  },
  methods: {
    addMemberAction() {
      this.addMemberDialog = true
    },

    inviteMember() {
      this.addMemberDialog = false
      this.$store.dispatch('invite', {
        email: this.newMemberEmail,
        role: this.newMemberGroup,
      })
      this.newMemberEmail = ''
      this.newMemberGroup = ''
    },
    resentInvite(inviteId) {
      this.$store.dispatch('resendInvite', inviteId)
    },

    deleteInvite(inviteId) {
      this.$store.dispatch('deleteInvite', inviteId)
    },
    deleteUser(userId) {
      this.$store.dispatch('deleteUser', userId)
    },

    getMemberGroupLabel(roleId) {
      for (const membersGroup of this.membersGroups) {
        if (membersGroup.id === roleId) {
          return membersGroup.name
        }
      }
    },
  },
}
</script>
