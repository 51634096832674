<template>
  <v-card class="">
    <v-card-text>
      Choose Project First
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>
